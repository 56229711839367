import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Dropdown, Stack } from "react-bootstrap";
import { EstablishmentSortType, LogLevelName } from "../../../app/enums";


interface ISortingProps {
    callbackSorting: any;
    isSortingDisabled: boolean;
}

export default function SortingEstablishment(props: ISortingProps) {
    const [sorting, setSorting] = useState<number>(0);

    const handleSorting = (sortBy: number) => {
        if (sorting && sorting === sortBy) {
            return;
        }
        setSorting(sortBy);
        props.callbackSorting(sortBy);
    }

    return (
        <Stack direction="horizontal" gap={3}>
                <Dropdown align={"start"} className={props.isSortingDisabled ? 'disabled' : ''}>
                    <Dropdown.Toggle variant="light" className="dropdown-sort" style={{ fontSize: 16 }} disabled={props.isSortingDisabled}>
                        <FontAwesomeIcon icon={faSort}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="timer-dropdown">
                    <Dropdown.Item 
                        onClick={() => handleSorting(EstablishmentSortType.CriticalCount)} 
                        className={`mb-1 font-14 border-0 ${sorting === EstablishmentSortType.CriticalCount ? "active" : ""}`}
                    >
                        {LogLevelName.Critical}
                    </Dropdown.Item>
                    <Dropdown.Item 
                        onClick={() => handleSorting(EstablishmentSortType.ErrorCount)} 
                        className={`mb-1 font-14 border-0 ${sorting === EstablishmentSortType.ErrorCount ? "active" : ""}`}
                    >
                        {LogLevelName.Error}
                    </Dropdown.Item>
                    <Dropdown.Item 
                        onClick={() => handleSorting(EstablishmentSortType.WarningCount)} 
                        className={`mb-1 font-14 border-0 ${sorting === EstablishmentSortType.WarningCount ? "active" : ""}`}
                    >
                        {LogLevelName.Warning}
                    </Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
            </Stack>
    );
}