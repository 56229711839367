import React, { useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  PaginationState
} from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { Stack, Table } from "react-bootstrap";
import { Pagination } from "react-bootstrap";
import SearchBar from "../search";
import Skeleton from "react-loading-skeleton";
import { SortOrder } from "../../app/enums";
import SortButton from "../button/SortButton";


interface IPagedFilterTableProps {
  tableKey: string;
  columns: any;
  data: any;
  usePagination: boolean;
  useSearch: boolean;
  pageRecords: number;
  fnFetchData: Function;
  onRowSelect?: any;
  title?: any;
  fnRowClick?: Function;
  useSorting?: boolean;
  useCustomSorting?: boolean;
}

type ColumnSort = {
  id: string
  desc: boolean
}

type SortingState = ColumnSort[]

export default function PagedFilterTable(props: IPagedFilterTableProps) {

  const [searchValue, setSearchValue] = useState<string>();
  const [activeRowIndex, setActiveRowIndex] = useState<number>(-1);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [sortType, setSortType] = useState<number>(3);;

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: props.pageRecords,
    });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
    searchValue,
    sortType,
    sortBy,
    sortOrder,
  };

  const { isLoading, data } = useQuery([props.tableKey, fetchDataOptions], () =>
    props.fnFetchData(fetchDataOptions), {
    refetchOnWindowFocus: false
  }
  );

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const onRowClicked = (row: any) => {
    setActiveRowIndex(row.index);
    props.fnRowClick?.(row);
  }

  const onHeaderClicked = (header: any) => {
    if (header.column.columnDef.enableSorting) {

      if (!(sorting.findIndex(obj => obj.id === header.column.id) > -1)) {
        if (sortOrder === SortOrder.ASC) {
          let sort = { id: header.column.id, desc: true } as ColumnSort;
          setSorting((prev) => [...prev, sort]);
          setSortOrder(SortOrder.DESC)
        } else if (sortOrder === SortOrder.DESC) {
          let sort = { id: header.column.id, desc: false } as ColumnSort;
          setSorting((prev) => [...prev, sort]);
          setSortOrder(SortOrder.ASC)
        } else {
          let sort = { id: header.column.id, desc: false } as ColumnSort;
          setSorting((prev) => [...prev, sort]);
          setSortOrder(SortOrder.ASC)
        }

      } else {
        let colums = sorting.find(col => col.id === header.column.id);
        if (colums?.desc === true) {
          setSorting((sortList) => sortList.filter((current) => current.id !== colums?.id));
          let sort = { id: header.column.id, desc: false } as ColumnSort;
          setSorting((prev) => [...prev, sort]);
          setSortOrder(SortOrder.ASC)
        } else if (colums?.desc === false) {
          setSorting((sortList) => sortList.filter((current) => current.id !== colums?.id));
          let sort = { id: header.column.id, desc: true } as ColumnSort;
          setSorting((prev) => [...prev, sort]);
          setSortOrder(SortOrder.DESC)
        }
      }
      setSortBy(header.column.id);
    }

  }
  const handleSelection = (selectedOption: any) => {
    setSortType(Number(selectedOption.value));
    table.setPageIndex(0);
  };

  const options = [
    { label: 'Unselected Establishments', value: '1' },
    { label: 'Selected Establishments', value: '2' },
    { label: 'Establishments A > Z', value: '3' },
  ];

  const table = useReactTable({
    data: data ?? props.data,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    enableRowSelection: true,
    onPaginationChange: setPagination,
    manualSorting: true,
    state: {
      pagination,
      sorting
    },
    debugTable: true,
    onSortingChange: setSorting,
  });
  return (
    <React.Fragment>

      {props.useSearch && <SearchBar placeholder="Search" className={props.useCustomSorting ? "search-box-left mb-3" : "mb-3"} onChange={(searchText: string) => { setSearchValue(searchText); table.setPageIndex(0); }} />}
      {props.useCustomSorting && <div><SortButton options={options} onSelect={handleSelection} /></div>}
      {
        props.title &&
        <Stack className="border border-bottom-0 w-100 table-title">
          {props.title}
        </Stack>
      }
      <Table responsive borderless>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    width:
                      header.getSize() !== 150
                        ? header.getSize() + "%"
                        : undefined,
                  }}
                >
                  {
                    props.useSorting ? <div style={{ width: header.getSize() ? header.getSize() + "%" : undefined, }}
                      onClick={() => props.useSorting ? onHeaderClicked(header) : () => false}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </div>

                      :
                      header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )

                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {!isLoading && table.getRowModel().rows.map(row => (
            <tr key={row.id} className={activeRowIndex === row.index ? "selected" : ""} onClick={() => props.fnRowClick ? onRowClicked(row) : () => false}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}

          {isLoading && table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (

                <td key={header.id}><Skeleton height={30} /></td>
              ))}
            </tr>
          ))}

          {table.getRowModel().rows.length === 0 && !isLoading && (
            <tr>
              <td colSpan={4} className="text-center">
                No records found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {props.usePagination && !isLoading && (
        <Pagination className="float-end mt-2">
          <Pagination.Item
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Pagination.Item>
          <Pagination.Item
            onClick={() => table.nextPage()}
            disabled={table.getRowModel().rows.length < props.pageRecords}
          >
            Next
          </Pagination.Item>
        </Pagination>
      )}
    </React.Fragment>
  );
}
