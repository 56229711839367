import React, { useState } from "react";
import Layout from "../layout";
import { createColumnHelper } from "@tanstack/react-table";
import "bootstrap/dist/css/bootstrap.css";
import { ServiceManager } from "../../services/ServiceManager";
import HubButton from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faArrowLeft, faCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import IEstablishment from "../../models/Establishment";
import EstablishmentCreate from "./create";
import { NavLink } from "react-router-dom";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import { EstablishmentSortType, EstablishmentStatus, EstablishmentStatusStyles, Permissions } from "../../app/enums";
import HUBTooltip from "../../components/tooltip";
import { PERMISSION_REQUIRED, RAG_SYSTEM_BASED_STATUS } from "../../app/constants";
import PagedFilterEstablishmentTable from "../../components/table/establishment-table";

export default function Establishment() {
  const [isCreateEstablishment, setCreateEstablishment] = useState(false);
  const [tableJobs, setTableJobs] = useState([]);
  const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);
  const columnHelper = createColumnHelper<IEstablishment>();
  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>Name</span>,
      size: 30,
      cell: ({ row }) => (
        <div className="long-text" title={row.original.establishmentID as string}>
          <NavLink to={`${row.original.establishmentID}`}>
            {row.original.name}
          </NavLink>
        </div>
      ),
    }),
    columnHelper.accessor('parent', {
      header: () => <span>Parent</span>,
      size: 30,
      cell: ({ row }) => (
        <div className="long-text" title={row.original.parent?.name as string}>
          <NavLink to={`/establishment/${row.original.parent?.establishmentID}`}>
            {row.original.parent?.name}
          </NavLink>
        </div>
      ),
    }),
    columnHelper.accessor('salesForceExternalID', {
      header: () => <span>External ID</span>,
      size: 10,
      cell: ({ row }) => (
        <div className="long-text" title={row.original.salesForceExternalID as string}>
          <a href={row.original.salesForceUrl} className="underline" target="_blank" rel="noreferrer">
            {row.original.salesForceExternalID}
          </a>
        </div>

      ),
    }),
    columnHelper.accessor('city', {
      header: () => <span>City</span>,
      size: 10,
      cell: ({ row }) => (
        <div className="short-text" title={row.original.city as string}>
          <span>{row.original.city}</span>
        </div>
      ),
    }),
    columnHelper.accessor('postCode', {
      header: () => <span>Postcode</span>,
      size: 20,
      cell: ({ row }) => (
        <div className="short-text" title={row.original.postCode as string}>
          <span>{row.original.postCode}</span>
        </div>
      ),
    }),
    columnHelper.accessor('status', {
      header: () => <span className="long-text">
        <HUBTooltip message={RAG_SYSTEM_BASED_STATUS} placement="top">
          <div className="d-flex right-margin24">
            <div className="d-flex right-margin10">Status</div>
            <div>
              <FontAwesomeIcon icon={faInfoCircle} size="sm" className="disabled right-margin10 right-margin24" />
            </div>
          </div>
        </HUBTooltip>
      </span>,
      size: 20,
      cell: ({ row }) => (
        <div className="loglevel-box">
          <div className={`p-1 ps-2 pe-2 logs ${EstablishmentStatusStyles[Number(row.original.status)]}`}>
            <FontAwesomeIcon icon={faCircle} size="2xs"
              className={`${EstablishmentStatusStyles[Number(row.original.status)]}`} />
            <span className="ms-2">{EstablishmentStatus[Number(row.original.status)]}</span>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor('criticalCount', {
      header: () => <span>Messages</span>,
      size: 20,
      cell: ({ row }) => (
        <div className="short-text w-auto">
          <div className="circle-icon critical" title={String(row.original.criticalCount)}>{row.original.criticalCount}</div>
          <div className="circle-icon error" title={String(row.original.errorCount)}>{row.original.errorCount}</div>
          <div className="circle-icon warning" title={String(row.original.warningCount)}>{row.original.warningCount}</div>
        </div>
      ),
    }),
  ];

  async function navigate(isTrue: boolean) {
    setCreateEstablishment(isTrue);
  }

  async function fetchData(options: {
    pageIndex: number;
    pageSize: number;
    searchValue: string;
    status: EstablishmentStatus | null;
    sortingType: EstablishmentSortType | null;
  }) {
    const establishmentResponse = await ServiceManager.EstablishmentService.GetAllWithChildern(false, options.pageIndex + 1, options.pageSize, options.searchValue, null, options.sortingType ?? EstablishmentSortType.Alphabetical, options.status);
    setTableJobs(establishmentResponse.data);
    return establishmentResponse.data;
  }

  return (
    isCreateEstablishment === false ? (
      <Layout title="Establishments"
        button={canEditEstablishments
          ?
          <HubButton text="Create New Establishment"
            className="btn btn-primary crbc-bg-color"
            FnOnClick={() => navigate(true)}
            icon={<FontAwesomeIcon icon={faAdd} />}></HubButton>
          :
          <HUBTooltip message={PERMISSION_REQUIRED} placement="bottom">
            <div className="disabled">
              <HubButton text="Create New Establishment"
                className="btn btn-secondary"
                FnOnClick={() => navigate(true)}
                disabled
                icon={<FontAwesomeIcon icon={faAdd} />}></HubButton>
            </div>
          </HUBTooltip>
        }>
        <div className="establishment">
          <PagedFilterEstablishmentTable
            tableKey={"establishments"}
            columns={columns}
            data={tableJobs}
            usePagination={true}
            useSearch={true}
            pageRecords={15}
            fnFetchData={fetchData}
          />
        </div>
      </Layout>
    )
      :
      (
        <Layout title="Create New Establishment"
          backArrow={
            <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(false)} className="me-4 fs-5 cursor-pointer" />
          }>
          <EstablishmentCreate createEstablishment={setCreateEstablishment} />
        </Layout>
      )
  );
};