import React from 'react';
import ReactPaginate from 'react-paginate';

interface IPagination {
    totalRecords: number;
    pageSize: number;
    currentPage: number;
    onPageChange: (selectedPage: number) => void;
    caller: string | null;
}

const HUBPagination: React.FC<IPagination> = ({
    totalRecords,
    pageSize,
    currentPage,
    onPageChange,
    caller
}) => {
    
    const pageCount = Math.ceil(totalRecords / pageSize);

    return (
        <div className="d-flex justify-content-between align-items-center">
            {!isNaN(totalRecords) && totalRecords !== 0 &&
                <span className='font-14'>
                    Showing {(currentPage - 1) * pageSize + 1} to {Math.min(currentPage * pageSize, totalRecords)} of {totalRecords} {caller}
                </span>
            }
            <ReactPaginate
                previousLabel={"Previous"}
                previousLinkClassName={"page-link"}
                nextLabel={"Next"}
                nextLinkClassName={"page-link"}
                breakLabel={"..."}
                breakLinkClassName={"page-link"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={(data) => onPageChange(data.selected + 1)}
                containerClassName={"pagination"}
                pageLinkClassName={"page-link"}
                disabledClassName={"li-disabled"}
                activeClassName={"active"}
                activeLinkClassName={"crbc-bg-color white-color crbc-border-color"}
                renderOnZeroPageCount={null}
                forcePage={currentPage - 1}
            />
        </div>
    );
};

export default HUBPagination;
