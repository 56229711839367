import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PagedFilterMessageTable from "../../components/table/message-table";
import Layout from "../layout";
import { createColumnHelper } from "@tanstack/react-table";
import "bootstrap/dist/css/bootstrap.css";
import { ServiceManager } from "../../services/ServiceManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircle, faShare } from "@fortawesome/free-solid-svg-icons";
import IMessage from "../../models/Message";
import { LogLevel, LogLevelStyles, Permissions } from "../../app/enums";
import CustomModal from "../../components/modal";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { GUID } from "../../app/common/helper/Guid";
import { NavLink } from "react-router-dom";
import { usePermissionCheck } from "../../app/common/helper/Permissions";

interface IMessageProps {
    StartDate?: any
    EndDate?: any
    Establishment?: any
    Device?: any
    Product?: any
    Package?: any
    DateTime?: any
    LogLevel?: any
    Category?: any
}

export default function Message(props: IMessageProps) {
    const [tableJobs, setTableJobs] = useState<any[]>([]);
    const [continuationTokens, setContinuationTokens] = useState<string[]>([""]);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [messageId, setMessageId] = useState("");
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let CanViewEstablishments: boolean = usePermissionCheck(Permissions.CanViewEstablishments);

    const columnHelper = createColumnHelper<IMessage>();
    const columns = [
        columnHelper.accessor('establishmentName', {
            header: () => <span>Establishment</span>,
            size: 15,
            minSize: 15,
            maxSize: 15,
            cell: ({ row }) => (
                <div className="long-text" title={row.original.establishmentName as string}>
                    {
                        CanViewEstablishments ?
                            <NavLink to={`/establishment/${row.original.establishmentID}`}>
                                {row.original.establishmentName}
                            </NavLink>
                            :
                            row.original.establishmentName
                    }
                </div>
            ),
        }),
        columnHelper.accessor('salesForceExternalID', {
            header: () => <span>External ID</span>,
            size: 12,
            minSize: 12,
            maxSize: 12,
            cell: ({ row }) => (
                <div className="long-text" title={row.original.establishmentName as string}>
                    <a href={row.original.salesForceUrl} className="underline" target="_blank" rel="noreferrer">
                        {row.original.salesForceExternalID}
                    </a>
                </div>
            ),
        }),
        columnHelper.accessor('deviceName', {
            header: () => <span>Device</span>,
            size: 12,
            minSize: 12,
            maxSize: 12,
            cell: ({ row }) => (
                <div className="short-text" title={row.original.deviceName as string}>
                    <span>{row.original.deviceName}</span>
                </div>
            ),
        }),
        columnHelper.accessor('productName', {
            header: () => <span>Product</span>,
            size: 12,
            minSize: 12,
            maxSize: 12,
            cell: ({ row }) => (
                <div className="short-text" title={row.original.productName as string}>
                    <span>{row.original.productName}</span>
                </div>
            ),
        }),
        columnHelper.accessor('packageName', {
            header: () => <span>Package</span>,
            size: 12,
            minSize: 12,
            maxSize: 12,
            cell: ({ row }) => (
                <div className="short-text" title={row.original.packageName as string}>
                    <span>{row.original.packageName}</span>
                </div>
            ),
        }),
        columnHelper.accessor('dateCreated', {
            header: () => <span>Date/Time</span>,
            size: 12,
            minSize: 12,
            maxSize: 12,
            cell: ({ row }) => (
                <div className="short-text" title={new Date(row.original.dateCreated as string).toLocaleString()}>
                    <span>{new Date(row.original.dateCreated as string).toLocaleString()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('category', {
            header: () => <span>Category</span>,
            size: 12,
            minSize: 12,
            maxSize: 12,
            cell: ({ row }) => (
                <div className="short-text" title={row.original.category as string}>
                    <span>{row.original.category}</span>
                </div>
            ),
        }),
        columnHelper.accessor('logLevel', {
            header: () => <span>Log Level</span>,
            size: 12,
            minSize: 12,
            maxSize: 12,
            cell: ({ row }) => (
                <div className="loglevel-box">
                    <div className={`p-1 ps-2 pe-2 logs ${LogLevelStyles[row.original.logLevel]}`}>
                        <FontAwesomeIcon icon={faCircle} size="2xs"
                            className={`${LogLevelStyles[row.original.logLevel]}`} />
                        <span className="ms-2">{LogLevel[row.original.logLevel]}</span>
                    </div>
                </div>

            ),
        }),
        columnHelper.accessor('message', {
            header: () => <span>Message</span>,
            size: 12,
            cell: ({ row }) => (
                <div className="message-text">
                    <Button className="btn btn-light crbc-bg-color" onClick={() => {
                        onRowClick(row);
                        setShowMessageModal(true);
                    }}>View</Button>
                </div>
            ),
        })
    ];

    async function resetContinuationToken() {
        setContinuationTokens([""]);
    }

    async function fetchData(options: {
        startDate?: string;
        endDate?: string;
        pageIndex: number;
        pageSize: number;
        searchValue?: string
        logLevels?: number[];
        categoryName?: string;
        establishmentId?: GUID;
        deviceId?: GUID;
        productId?: GUID;
        packageId?: GUID;
        messageId?: GUID;
    }) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        // Get Query string Value
        const startDate = params.get('startDate')
        const finishDate = params.get('finishDate')
        const messageId = params.get('messageId')
        const establishmentId = params.get('establishmentId')
        const deviceId = params.get('deviceId')
        const productId = params.get('productId')
        const packageId = params.get('packageId')
        const category = params.get('category')
        const logLevelParam = params.get('logLevel') ? params.get('logLevel')?.split(',').map(Number) : null;
        const logLevelFilter = options.logLevels ? options.logLevels as number[] : logLevelParam as number[];
        const deviceIdOpt = deviceId && deviceId !== "undefined" ? deviceId as GUID : options.deviceId;
        const establishmentIdOpt = establishmentId && establishmentId !== "undefined" ? establishmentId as GUID : options.establishmentId;
        const productIdOpt = productId && productId !== "undefined" ? productId as GUID : options.productId;
        const packageIdOpt = packageId && packageId !== "undefined" ? packageId as GUID : options.packageId;
        const categoryOpt = category ? category as string : options.categoryName;
        // Date Option Set
        const startDateOpt = options.startDate ? options.startDate : startDate as string;
        const finishDateOpt = options.endDate ? options.endDate : finishDate as string;
        const messageIdOpt = messageId && messageId !== "undefined" ? messageId as GUID : options.messageId;
        const continuationToken = continuationTokens[options.pageIndex];
        //Messages filtering call

        let establishmentResponse: any;
        establishmentResponse = await ServiceManager.MessageService.GetAll(startDateOpt, finishDateOpt, options.pageIndex + 1,
            options.pageSize, continuationToken, logLevelFilter, categoryOpt, establishmentIdOpt, deviceIdOpt, productIdOpt, packageIdOpt, messageIdOpt)

        if (messageId) {
            setMessageId(messageId);
            let popUpTextMessage = establishmentResponse.data.data.find((m: { id: string; }) => m.id === messageId);
            if (popUpTextMessage) {
                setMessageText(popUpTextMessage.message);
            }
        }

        if (!continuationTokens.includes(establishmentResponse.data.continuationToken)) {
            setContinuationTokens((prevTokens) => [...prevTokens, establishmentResponse.data.continuationToken]);
        }

        setTableJobs(establishmentResponse.data);
        return establishmentResponse.data;
    }

    useEffect(() => {
        if (messageId && messageText) {
            setShowMessageModal(true);
        }
    }, [messageId, messageText]);

    const MessageListHeader = () => {
        return (
            <Stack>
                <p className="heading">Message</p>
            </Stack>
        )
    }

    function onRowClick(row: any) {
        setMessageText(row.original.message);
        setMessageId(row.original.id);
    }


    function removeMessageIdFromURL(): string {
        return window.location.href.replace(/&[A-Za-z]+=[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/i, "");
    }

    async function copyToClipboard() {
        try {
            await navigator.clipboard.writeText(removeMessageIdFromURL() + "&messageId=" + messageId);
            ServiceManager.ToastServer.showSuccess("The URL has been created and copied to clipboard.");
        } catch (error) {
            ServiceManager.ToastServer.showError("There was some issue while copying the URL to clipboard.");
        }
    }

    const Message = () => {
        return (
            <Stack>
                <div className="messagebox-border p-2 mw-100 messagebox-height"
                    style={{ overflowY: "scroll", height: "500px" }}>
                    <pre className="text-wrap-next-line">
                        {messageText}
                    </pre>
                </div>
                <Row>
                    <Col>
                        <Button variant="popup-btn btn btn-light w-100 mt-3" type="submit" onClick={() => {
                            setShowMessageModal(false);
                        }}>
                            Close
                        </Button>
                    </Col>
                    <Col>
                        <Button variant="popup-btn btn btn-primary crbc-bg-color w-100 mt-3" onClick={copyToClipboard}>
                            <FontAwesomeIcon icon={faShare} /><span style={{ marginLeft: "10px" }}>Share</span>
                        </Button>
                    </Col>
                </Row>
            </Stack>
        )
    }

    function navigateBack() {
        let fromDashboard = searchParams.get('fromDashboard');
        let establishmentId = searchParams.get('establishmentId');
        if (fromDashboard) {
            navigate(`/`);
        } else {
            navigate(`/establishment/${establishmentId}`);
        }
    }
    return (

        <Layout title="Messages"
            backArrow={
                <FontAwesomeIcon icon={faArrowLeft} onClick={(e) => navigateBack()}
                    className="me-4 fs-5 cursor-pointer" />
            }>
            <CustomModal customClass={'text-overflow-control'}
                isShow={showMessageModal} handleClose={() => {
                    setShowMessageModal(false)
                }} header={<MessageListHeader />} size="lg">
                <Message />
            </CustomModal>
            <div className="messages">
                <PagedFilterMessageTable
                    tableKey={"messages"}
                    columns={columns}
                    data={tableJobs}
                    usePagination={true}
                    useSearch={false}
                    pageRecords={10}
                    fnFetchData={fetchData}
                    fnResetContinuationToken={resetContinuationToken}
                />
            </div>
        </Layout>
    );
};