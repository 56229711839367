import { useState, } from "react";
import { Button, ListGroup, Stack } from "react-bootstrap"
import { LogLevel } from "../../app/enums";
import { AddUrlFilter } from "../../app/common/helper/MessageFilter"

interface ILoglevelFilterProps {
    handleClose: any;
    field: any;
    startDate: any;
    endDate: any;
    pageIndex: number;
    pageSize: number;
    handleLogLevelFilter: any;
    handleApplyFilter: any;
    selectedLogLevels: number[];
}
export default function LoglevelFilter(props: ILoglevelFilterProps) {
    const [selectedLogLevels, setSelectedLogLevels] = useState<number[]>(props.selectedLogLevels);

    const handleApply = () => {
        props.handleLogLevelFilter(selectedLogLevels.join(','));
        props.handleApplyFilter();
        props.handleClose(false);
        AddUrlFilter("logLevel", selectedLogLevels.join(','));
    };

    const handleCheckboxChange = (value: any) => {
        setSelectedLogLevels((prev: any) =>
          prev.includes(value)
            ? prev.filter((item: any) => item !== value)
            : [...prev, value]
        );
      };

    const LogLevelOptions = [
        { value: LogLevel[LogLevel.Trace], key: LogLevel.Trace },
        { value: LogLevel[LogLevel.Debug], key: LogLevel.Debug },
        { value: LogLevel[LogLevel.Information], key: LogLevel.Information },
        { value: LogLevel[LogLevel.Warning], key: LogLevel.Warning },
        { value: LogLevel[LogLevel.Error], key: LogLevel.Error },
        { value: LogLevel[LogLevel.Critical], key: LogLevel.Critical },
        { value: LogLevel[LogLevel.None], key: LogLevel.None }
    ];

    return (
        <Stack>
            <ListGroup className="list-group-sm checklist-filter mt-2">
                {LogLevelOptions.map((option) => (
                    <div key={option.key} className="form-check ps-0 mb-2 d-flex align-items-center">
                        <input
                            type="checkbox"
                            className="form-check-input m-0 me-2 cursor-pointer"
                            id={`opt-${option.key}`}
                            name="statusOptions"
                            value={option.key}
                            checked={selectedLogLevels.includes(option.key)}
                            onChange={() => handleCheckboxChange(option.key)}
                        />
                        <label className="form-check-label cursor-pointer" htmlFor={`opt-${option.key}`}>
                            <span style={{ fontSize: "16px" }}>{option.value}</span>
                        </label>
                    </div>
                ))}
            </ListGroup>
            <Stack direction="horizontal" className="mt-3">
                <Button variant="popup-btn right-margin10 btn-outline-secondary w-100" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="popup-btn btn btn-primary crbc-bg-color w-100" onClick={handleApply}>
                    Apply
                </Button>
            </Stack>
        </Stack>
    )
}